@import '../../index.scss';

.login-wrapper {
    max-width: 1920px;
    width: $full;
    margin: $zero $auto;
    @include flexbox();
    @include flex-wrap(wrap);
    height: auto;
    .login-left {
        width: $half - 1;
        padding: $twenty ($twenty - $five);
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items($center);
        
    }
    .login-right {
        width: $half + 1;
        position: $relative;
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items($center);
        .kitchen-cabinet {
            text-align: $center;
            width: $full;
            height: 100%;
            img {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
        .login-title {
            position: $absolute;
            left: $fifty + 7;
            top: 5%;
            h2 {
                font-size: $fourty - 4;
                font-weight: $Extra-light;
                line-height: $fifty - 2;
                margin: 0 0 30px 0;
                span {
                    font-weight: $Medium;
                    display: $block;
                }
            }
        }
    }
}

@media screen and (min-width:1441px) {
    .login-wrapper {
        .login-right {
            .kitchen-cabinet {
                img {
                    height: calc(100vh - 134px) !important;
                }
            }
        }
        &.signup-page {
            .login-right {
                .kitchen-cabinet {
                    img {
                        height: 100% !important;
                    }
                }
            }
        }
    }
}

@media screen and  (max-width: $device__l) {
    .login-wrapper {
        .login-left,
        .login-right {
            width: $full;
        }
        .login-right {
            .login-title {
                top: 5%;
            }
        }
    }
}

@media screen and  (max-width: $device__s) {
    .login-wrapper {
        .login-right {
            .login-title {
                h2 {
                    font-size: $twenty + $five;
                    line-height: $thirty;
                }
            }
        }
    }
}

@media screen and  (max-width: $device__xs) {
    .login-wrapper {
        .login-right {
            .login-title {
                left: $twenty - $five;
                font-size: $twenty;
            }
        }
    }
}


