@import "../../index.scss";

#tab-category-container {
  display: block;
  float: left;
  width: $full;

  .tag-category {
    font-size: 12px;
    margin-right: $twenty - 5;

    .tag-category-label {
      display: inline-block;
      margin: $five;
    }

    #tag {
      display: inline-block;
      margin: $five;
      background-color: $color_white;
      border: 1px solid $color_gainsboro; //#ccc;
      color: $color_matterhorn; //#4a4a4a;
      border-radius: $five - 1;
    }

    .tag-category {
      font-size: 12px;
    }
  }

  button#clearAll {
    color: $clor_skyblue; //#f36a10;
    border: none;
    background: transparent;
    font-weight: normal;
    font-size: 12px;
    margin: $ten - 2 $zero;
    padding: $zero $ten;
    display: inline-block;
    height: auto;
    z-index: auto;

    &:hover {
      color: $color_white;
    }
  }
}

@media screen and (max-width: $device__xs) {
  #tab-category-container {
    float: none;
  }
}