// border box
*,
:before,
:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

$primary_color: var(--primary_color);
$secondary_color: var(--secondary_color);
$primary_button:var(--primary_button_color);
$primary_button_hover:var(--primary_button_hover_color);
$primary_button_text:var(--primary_button_text_color);
$primary_button_text_hover:var(--primary_button_text_hover_color);
$secondary_button:var(--secondary_button_color);
$secondary_button_hover:var(--secondary_button_hover_color);
$secondary_button_text:var(--secondary_button_text_color);
$secondary_button_text_hover:var(--secondary_button_text_hover_color);
$outline_button:var(--outline_button_color);
$outline_button_hover:var(--outline_button_hover_color);
$outline_button_text:var(--outline_button_text_color);
$outline_button_text_hover:var(--outline_button_text_hover_color);
$primary_text:var(--primary_text_color);
$text_link:var(--text_link_color);
$text_link_hover:var(--text_link_hover_color);
$menu_color:var(--menu_color);
$menu_color_hover:var(--menu_color_hover);
$menu_color_active:var(--menu_color_active);

// Colors
$color_white: #ffffff;
$color_black: #000000;
$color_black2: #1A1818;
$dark_black: #363D47;
$clor_skyblue: $secondary_color;
$color_matterhorn: #555555;
$color_whitesmoke: #F2F2F2;
$color_verylightgrey: #CECECE;
$color_brightturquoise: #00F5D4;
$color_red: #FF0000;

$blue: #232833;
$blue2: #3C414D;
$blue3: #363a46;
$color_orange: $secondary_color;
$color_orangelight: #ffe5cf;
$color_grey: #848484;
$color_grey2: #e5e5e5;
$color_grey3: #9a9a9a;
$color_grey4: #828282;
$color_grey5: #d8d8d8;
$color_grey6: #a4a4a4;
$color_grey7: #8D95A7;
$color_grey8: #5D5757;
$color_grey9: #B7B7B7;
$color_grey10: #f3f3f3;
$color_grey11: #f4f4f4;
$color_grey12: #e8e8e8;
$color_grey13: #545454;
$color_grey14: #dbdbdb;
$color_grey15: #eeeeee;
$color_pattensblue: #D1F2FC;
$color_aliceblue: #F1FBFF;
$transparent: transparent;
$color_gainsboro: #DFDFDF;
$color_radicalred: #F64E60;
$color_palepink: #FFE1E4;
$color_pattensblue: #D2F4FF;
$color_pattensblue2: #D9ECFF;
$color_dodgerblue: #3699FF;
$color_lightcyan: #CEFFFD;
$color_lightseagreen: #1BC5BD;
$clor_nobel: #9B9B9B;
$color_green: #70c195;
$color_yellow: #ffbb00;
$color_seashell: #fff3eb;
$peach_crayola: #f5c597;
$peach_color: #FFF5EC;
$peach_dark: #F2E5D9;
$green_color: #26A926;
$blue_light: #1781BE;

// px
$auto:auto;
$zero: 0;
$five: 5px;
$ten: 10px;
$twenty: 20px;
$thirty: 30px;
$fourty: 40px;
$fifty: 50px;
$sixty: 60px;
$seventy: 70px;
$hundred: 100px;
// Font Waight
$Thin: 100;
$Extra-light: 200;
$Light: 300;
$normal: 400;
$Medium: 500;
$Semi-Bold: 600;
$bold: 700;
$Extra-bold: 800;
$Black: 900;
// font-style
$italic:italic;
// %
$full: 100%;
$half: 50%;
// block
$none: none;
$block: block;
$inline: inline;
$inline-block: inline-block;
// inline
$left: left;
$right: right;
$center: center;
$middle:middle;
// z-index
$zindex-low: 9;
$zindex-medium: 99;
$zindex-high: 999;
$zindex-higher: 9999;
$super-zindex: 99999999;
// Position
$absolute: absolute;
$relative: relative;
$static: static;
$fixed: fixed;
// text-transform
$uppercase: uppercase;
$lowercase: lowercase;
$capitalize: capitalize;
// visibility 
$visible:visible; 
$hidden:hidden;
// Responsive
$device__xs: 480px;
$device__s: 640px;
$device__m: 767px;
$device__mm: 768px;
$device__l: 1023px;
$device__xll: 1199px;
$device__xl: 1140px;
$device__xlll: 1440px;
// vertical align 
$middle: middle;
$top: top;
$bottom: bottom;
$baseline: baseline;
// mixin
@mixin transition ($transition) {
    transition: $transition;
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
}

@mixin opacity ($opacity) {
    opacity: $opacity;
    -moz-opacity: $opacity;
    -webkit-opacity: $opacity;
}

@mixin box-shadow ($string) {
    box-shadow: $string;
    -webkit-box-shadow: $string;
    -moz-box-shadow: $string;
}

@mixin transform($property) {
    transform: $property;
    -webkit-transform: $property;
    -ms-transform: $property;
}

@mixin border-radius($radius) {
    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
}

@mixin translate ($x, $y: 0) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
}

@mixin flexbox() {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
}

@mixin flex-wrap($wrap) {
    flex-wrap: $wrap;
    -webkit-flex-wrap: $wrap;
    -ms-flex-wrap: $wrap;
}

@mixin justify-content($justify) {
    justify-content: $justify;
    -webkit-justify-content: $justify;
    -moz-justify-content: $justify;
    -ms-justify-content: $justify;
    -ms-flex-pack: $justify;
}

@mixin align-items($align) {
    align-items: $align;
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
}

@mixin translate($x, $y) {
    transform: translate($x, $y);
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
}

// Container
.container {
    max-width: 1170px;
    width: $full;
    margin: $zero $auto;
    padding-left: $ten + $five;
    padding-right: $ten + $five;
}

button {
    &:active,
    &:focus {
        outline: $none;
    }
}

// Title 
h1 {
    font-size: $twenty + 4;
    line-height: $fourty - $five;
    font-weight: $Semi-Bold;
    color: $color_black;
    margin: $zero $zero $thirty;
    &.center {
        text-align: $center;
    }
}

// Required 
.required {
    &:after {
        content: "*";
        color: $color_radicalred;
    }
}

// Clear
@mixin clearfix() {
    &:before,
    &:after {
        display: table; // 2
        content: " "; // 1
    }
    &:after {
        clear: both;
    }
}

.cf {
    @include clearfix();
}

// Sprite 
@mixin sprite() {
    background-image: url(./assets/images/pms-sprite.png);
    background-repeat: no-repeat;
    display: $inline-block;
    vertical-align: $middle;
    font-size: $zero;
}

.sprite {
    @include sprite();
}



// Font Family 
$Poppins: 'Poppins',
arial,
sans-serif;

$monterest: 'Montserrat', sans-serif;
// Black Button
.btn {
    background-color: $color_white;
    border: 1px solid $color_black;
    text-align: $center;
    text-transform: $uppercase;
    color: $color_black;
    font-size: $twenty - 4;
    font-weight: $bold;
    padding: ($ten - 2) ($fourty + $five + 1);
    height: $fifty;
    cursor: pointer;
    outline: $none;
    position: $relative;
    font-family: $Poppins;
    @include border-radius($ten - 2);
    @include transition (.5s);
    z-index: $zindex-low;
    &::before {
        @include border-radius($ten - 2);
        content: "";
        position: $absolute;
        z-index: -1;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background: $secondary_color;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    &:hover {
        // background-color: $clor_skyblue;
        // border-color: $clor_skyblue;
        // color: $color_white;
        color: $color_white;
        background-color: $transparent;
        border-color: $transparent;
        &:before {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
        }
    }
    &.btn-black {
        background-color: $primary_button;
        border-color: $primary_button;
        color: $primary_button_text;
        &:hover {
            background-color: $transparent;
            border-color: $transparent;
            color: $primary_button_text_hover;

            &:before{
                background-color: $primary_button_hover;
                border-color: $primary_button_hover;
            }
        }
    }
    &.btn-b-blue {
        color: $secondary_button_text;
        border-color: $secondary_button;
        background-color: $secondary_button;
        &:hover {
            background-color: $secondary_button_hover;
            border-color: $secondary_button_hover;
            color: $secondary_button_text_hover;

            &:before{
                background-color: $secondary_button_hover;
            }
        }
    }

    &.back{
        background: transparent;
        border-color: $primary_color;
        color: $outline_button_text;
        &:hover{
            color: $outline_button_text_hover;

            &:before{
                background-color: $outline_button_hover;
            }
        }
    }
}

body {
    margin: $zero;
    font-family: $Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $color_whitesmoke;
    font-weight: $normal;
    color: $color_black;
    font-size: $ten + 4;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
    border: $none;
    max-width: $full;
}

a {
    text-decoration: $none;
}

ul,
li {
    list-style: $none;
    padding: $zero;
    margin: $zero;
}

// input 
.input-box {
    background-color: $transparent;
    border: 1px solid $color_verylightgrey;
    @include border-radius($five);
    color: $color_black;
    font-size: $twenty - 4;
    height: $fourty - 4;
    line-height: $twenty + $five;
    padding: $zero $twenty - $five;
    vertical-align: $baseline;
    &:disabled {
        @include opacity (0.3);
    }
    &:focus {
        outline: $none;
        border-color: $clor_skyblue;
    }
}

// Select box
.select-box {
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: $twenty - 4;
    line-height: $twenty + $five;
    color: $color_matterhorn;
    width: $full;
    background-color: $transparent;
    background-image: url(./assets/images/select-optin.png);
    background-repeat: no-repeat;
    background-position: $full $zero;
    color: $color_matterhorn;
    font-family: $Poppins;
}

// Custom Checkbox
.check-box {
    position: $relative;
}

.custom-checkbox {
    width: $thirty;
    height: $thirty;
    margin: $zero $ten $zero $zero;
    vertical-align: $middle;
    position: $absolute;
    top: $zero;
    z-index: $zindex-low;
    @include opacity ($zero);
    &+.label {
        position: $relative;
        margin-right: $zero;
        padding-left: $fourty;
        display: $block;
        &::before {
            content: "";
            width: $thirty;
            height: $thirty;
            display: $block;
            background: $color_white;
            border: 1px solid $color_verylightgrey;
            @include border-radius($five + 1);
            position: $absolute;
            left: $zero;
            top: -$five + 1;
            @include transition (0.5s);
        }
    }
    &:checked {
        &+.label {
            &::before {
                background: $clor_skyblue;
                border-color: $clor_skyblue;
            }
            &::after {
                content: "";
                display: $block;
                position: $absolute;
                left: $five + 2;
                top: $five - 1;
                @extend .sprite;
                background-position: -161px -68px;
                width: $twenty - 3;
                height: $ten + 3;
                @include transition (0.5s);
            }
        }
    }
}

// inches
.inches-box {
    .inches {
        font-size: $ten + 2;
        color: $clor_nobel;
    }
    .inch-input {
        width: $hundred - $twenty;
        margin: $zero $ten;
        vertical-align: $middle;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            appearance: none;
            -webkit-appearance: none;
        }
        &[type="number"] {
            -moz-appearance: textfield;
        }
    }
    .label {
        color: $color_black;
    }
}

// Close Butoon
.close-btn {
    width: $fourty - $five;
    height: $fourty - $five;
    background: $color_white;
    border: 1px solid $color_verylightgrey;
    display: $block;
    text-align: $center;
    @include border-radius($fifty);
    cursor: pointer;
    &::before {
        content: "";
        @extend .sprite;
        background-position: -164px (-$hundred + 6);
        width: $ten + 1;
        height: $ten + 1;
        margin-top: $ten - 2;
    }
}

// shadow box with with background
.shadow-white-bg {
    padding: $fourty 4.3% ($fifty + $ten);
    background: #FFFFFF;
    box-shadow: 1px 1px 3px #0000000D;
    @include border-radius($ten + 2);
    margin-bottom: $fifty + $ten;
}

.without-middle-row {
    margin-top: $thirty;
}

.viewrequest-main {
    margin-top: $thirty;
}

.validation-warning {
    border-color: $color_red !important;
}

.validation-msg {
    color: $color_red !important;
}

.rdw-editor-toolbar {
    margin-bottom: $zero;
    @include border-radius($five $five $zero $zero);
}

.editor-class {
    border: 1px solid #F1F1F1;
    border-top: $zero;
    @include border-radius($zero $zero $five $five);
}

.remove-style {
    display: none !important;
}

.active-link {
    color: $clor_skyblue;
}
.status-container {
    display: flex;
    grid-gap: 15px;
}
.request-status {
    @include border-radius($five - 1);
    line-height: $twenty + 1;
    letter-spacing: 0.28px;
    padding: 3px $ten;
    &:before {
        content: "";
        @include border-radius($fifty);
        width: $ten - 2;
        height: $ten - 2;
        display: $inline-block;
        margin-right: $ten;
    }
    &.needs-approval {
        background: $color_palepink;
        color: $color_radicalred;
        &::before {
            background-color: $color_radicalred;
        }
    }
    &.to-be-continued {
        background: #FBF0AB;
        color: #64570A;
        &::before {
            background-color: #64570A;
        }
    }
    &.queued {
        background: $color_lightcyan;
        color: $color_lightseagreen;
        &:before {
            background-color: $color_lightseagreen;
        }
    }
    &.approved {
        background: #e0ffd8;
        color: #2a940a;
        &:before {
            background-color: #2a940a;
        }
    }
    &.sold {
        background: #FFC4C4;
        color: #AA0D0D;
        &:before {
            background-image: url(./assets/images/tick-icon-red.svg);
            background-position: bottom;
            width: 12px;
            height: 10px;
            background-repeat: no-repeat;
        }
    }
    &.publish {
        background: $color_brightturquoise;
        color: $color_white;
        &:before {
            background-color: $color_white;
        }
    }
    &.draft {
        background: #e2e2e2;
        color: #5a5a5a;
        &:before {
            background-color: #5a5a5a;
        }
    }
    &.in-progress {
        background: $color_pattensblue2;
        color: $color_dodgerblue;
        &:before {
            background-color: $color_dodgerblue;
        }
    }
    &.needs-information {
        background: $color_pattensblue2;
        color: $color_dodgerblue;
        &:before {
            background-color: $color_dodgerblue;
        }
    }
    &.information-submitted {
        background: $color_pattensblue2;
        color: $color_dodgerblue;
        &:before {
            background-color: $color_dodgerblue;
        }
    }
    &.revision-requested {
        background: $color_pattensblue;
        color: $clor_skyblue;
        &:before {
            background-color: $clor_skyblue;
        }
    }
    &.closed {
        background: $color_verylightgrey;
        color: $color_white;
        &:before {
            background-color: $color_white;
        }
    }
    &.revision-due-to-design-error {
        background: $peach_crayola;
        color: $color_matterhorn; //#4e4e4e
        &:before {
            background-color: $color_matterhorn; //#4e4e4e
        }
    }
}


.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .clearfix { display: inline-block; }
  /* start commented backslash hack \*/
  * html .clearfix { height: 1%; }
  .clearfix { display: block; }

  .footer-nav ul{
      justify-content: center;
  }
//   .ql-editor{
//     img{
//         width: 25%;
//         cursor: pointer;
//      }
//   }
